<template>
  <b-modal :id="'icc' + cases.id" size="xl" centered hide-footer :title="cases.title">
    <div style="text-align: center">Выберите вариант обучения</div>
    <div class="row" style="width: 100%;margin: auto; justify-content: center">
      <div style="text-align: center"
          class="col-sm-6">
        <b-card @click="selectedCase"
                title="Обучение кейсовым методом"
                img-src="https://img1.goodfon.ru/original/1366x768/c/a8/art-kino-cinema-papka-dve.jpg"
                img-alt="Image"
                img-top
                tag="article"
                style="max-width: 20rem; margin: auto"
                class="mb-2"
        >
          <b-card-text>
            <b-button variant="outline-success">Выбрать</b-button>
            <div>После регистрации на нашем сайте на Ваш e-mail придет письмо со ссылкой на облачный сервис, где Вы можете
            скачать все материалы учебного курса и продолжить работу без доступа к сети Интернет. После их изучения
            необходимо ответить на вопросы экзаменационного билета и переслать его скан на электронную почту, указанную
              в инструкции к курсу.</div>
          </b-card-text>

        </b-card>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "itemCase",
  props: ['cases'],
  methods: {
    selectedCase() {

      let data = {
        type: 'case',
        title: this.cases.title,
        id_c: this.cases.case_sdo.id,
        documents: {
          snils: this.cases.case_sdo.is_snils,
          document_of_education: this.cases.case_sdo.document_of_education,
        }
      }
      this.$emit('respSelected', data)
      this.$bvModal.hide('icc' + this.cases.id)
    }
  }
}
</script>

<style scoped>

</style>