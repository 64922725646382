import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        url: 'https://csdo.bmipk.ru/',
        // url: 'http://127.0.0.1:8000/'
    },
    mutations: {},
    actions: {},
    modules: {},
    getters: {
        getUrl(state) {
            return state.url
        }
    }
})
