<template>
  <div class="courses_block row">
    <br>
    <hr>
    <div v-for="(item,index) in courses" :key="index"
         :class="{ 'card col-sm-3': variant.v1, '': variant.v2, 'ccard col-sm-9':item.description}"
         @click="getDeafultCourseSubSections(item.id)"
         v-b-modal="'modal-courses' + item.id">
      <div>
        <div class="card_content">
          <div class="card-title custom_card" :style="{ textAlign: variant.text_position}">{{ item.title }}</div>
          <div style="text-align: left; font-size: 14px; margin-top: 15px" v-if="variant.v3"
               v-html="item.description"></div>
        </div>
        <b-modal class="modal-courses" :id="'modal-courses' + item.id" size="xl" ok-only centered>
          <template #modal-header>
            <div class="card-title">{{ item.title }}</div>
          </template>
          <div v-if="item.subsections.length>1">
            <div v-for="subsection in item.subsections">
              <div></div>
              <div class="btn_c_course" v-b-toggle="'collapse'+subsection.id">{{ subsection.title }}</div>
              <b-collapse :id="'collapse'+subsection.id" class="mt-2">
                <b-card>
                  <div v-for="(course,index) in subsection.courses" :key="index + 'course'">
                    <div @click="selectedCourse(course)"
                         class="title_course"
                         :class="{'checked': checkSelected(course)}"
                    >
                      <b-icon class="check_icon" v-if="checkSelected(course)"
                              icon="check-square" font-scale="1.5" variant="success"></b-icon>
                      <b-icon class="check_icon" v-else
                              icon="square" font-scale="1.5" variant="success"></b-icon>
                     {{ course.title }}
                    </div>
                  </div>
                </b-card>
              </b-collapse>
            </div>
          </div>
          <div v-else>
            <div v-for="subsection in item.subsections">
              <div v-for="(course,index) in subsection.courses" :key="index + 'course'">
                <div @click="selectedCourse(course)"
                     class="title_course"
                     :class="{'checked': checkSelected(course)}"
                >
                  <b-icon class="check_icon" v-if="checkSelected(course)"
                          icon="check-square" font-scale="1.5" variant="success"></b-icon>
                  <b-icon class="check_icon" v-else
                          icon="square" font-scale="1.5" variant="success"></b-icon>
                  {{ course.title }}
                </div>
              </div>
              <div v-for="(case_,index) in subsection.cases" :key="index + 'case_'">
                <div @click="selectedCase(case_)"
                     class="title_course"
                     :class="{'checked': checkSelected2(case_)}"
                >
                  <b-icon class="check_icon" v-if="checkSelected2(case_)"
                          icon="check-square" font-scale="1.5" variant="success"></b-icon>
                  <b-icon class="check_icon" v-else
                          icon="square" font-scale="1.5" variant="success"></b-icon>
                  {{ case_.title }}
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import ItemCourse from "@/components/orderTN/itemCourse";
import ItemCase from "@/components/orderTN/itemCase";
import {mapGetters} from "vuex";

export default {
  name: "coursesAccardion",
  components: {ItemCase, ItemCourse},
  props: ['courses', 'selected_courses', 'selected_cases', 'deleteCourse', 'deleteCase', 'sections'],
  data() {
    return {
      variant: {
        v1: true,
        v2: false,
        v3: true,
        text_position: 'center'
      },
      search: '',
      search_res: [],
      tmpselected_courses: [],
      ttt: [],
      coursesSolo: [],
      courses_in_section: []
    }
  },
  watch: {
    search: function () {
      if (this.search.length) {
        this.search_res = []
        let cases = this.courses.complex_c_c.filter(elem => elem.cases.find(el => el.title.toLowerCase().includes(this.search.toLowerCase())))
        let courses = this.courses.complex_c_c.filter(elem => elem.courses.find(el => el.title.toLowerCase().includes(this.search.toLowerCase())))
        for (let i = 0; i < cases.length; i++)
          this.search_res.push(cases[i].id)
        for (let i = 0; i < courses.length; i++)
          this.search_res.push(courses[i].id)
      } else {
        this.search_res = []
      }
    },
    tmpselected_courses: function () {
      let data = {
        courses: [],
        cases: [],
      }
      for (let i = 0; i < this.tmpselected_courses.length; i++) {
        if (this.tmpselected_courses[i].type === 'case') {
          data.cases.push(this.tmpselected_courses[i])
        } else if (this.tmpselected_courses[i].type === 'course') {
          data.courses.push(this.tmpselected_courses[i])
        }
      }
      this.$emit('selected_course_and_case', data)
    },
    deleteCourse: function () {
      let course = this.tmpselected_courses.findIndex(elem => elem.id === this.deleteCourse.id)
      this.tmpselected_courses.splice(course, 1)
    },
    deleteCase: function () {
      let cases = this.tmpselected_courses.findIndex(elem => elem.id_c === this.deleteCase.id_c)
      this.tmpselected_courses.splice(cases, 1)
    }
  },
  methods: {
    ...mapGetters(['getUrl']),
    async getDeafultCourseSubSections(subsectionid) {
      this.courses_in_section = []
      let url = this.getUrl() + 'forms/default-form/sub-section/?id=' + subsectionid
      await fetch(url, {
        method: "get",
        credentials: "same-origin",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      })
          .then(response => response.json())
          .then(response => {
                this.courses_in_section = response.courses
              }
          )
    },
    selectedCourse(course) {
      if (this.tmpselected_courses.some(elem => elem.id == course.id))
        this.tmpselected_courses.splice(this.tmpselected_courses.findIndex(elem => elem.id === course.id), 1)
      else {
        let data = {
          type: 'course',
          title: course.title,
          id: course.id,
          documents: {
            snils: course.is_snils,
            document_of_education: course.document_of_education,
          }
        }
        this.tmpselected_courses.push(data)
      }
    },
    selectedCase(cases) {
      if (this.tmpselected_courses.some(elem => elem.id_c == cases.id))
        this.tmpselected_courses.splice(this.tmpselected_courses.findIndex(elem => elem.id_c === cases.id), 1)
      else {
        let data = {
          type: 'case',
          title: cases.title,
          id_c: cases.id,
          documents: {
            snils: cases.is_snils,
            document_of_education: cases.document_of_education,
          }
        }
        this.tmpselected_courses.push(data)
      }
    },
    checkSelected(course) {
      if (course.length) {
        return this.tmpselected_courses.some(elem => elem.id == course.id);
      } else {
        return this.tmpselected_courses.some(elem => elem.id == course.id)
      }
    },
    checkSelected2(case_) {
      if (case_.length) {
        return this.tmpselected_courses.some(elem => elem.id_c == case_.id);
      } else {
        return this.tmpselected_courses.some(elem => elem.id_c == case_.id)
      }
    },
  },

}
</script>

<style lang="scss">
.custom_card {
  font-weight: bold;
}

.btn_c_course {
  text-align: left;
  font-weight: bolder;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
  background: #b9daec;

}

.check_icon {
  margin-right: 15px;
}

.setclassSearch {
  box-shadow: 2px 2px 10px green;
  animation: pulse 1s infinite;
}

.setclassSearchForC {
  font-weight: bold;
  //box-shadow: 0 4px 2px -2px green;
  padding: 2px;
  border-radius: 5px;
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 128, 0, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 128, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 128, 0, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 128, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(0, 128, 0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(0, 128, 0, 0);
    box-shadow: 0 0 0 10px rgba(0, 128, 0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 128, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 128, 0, 0);
  }
}


.search_course {
  display: flex;
  align-items: center;
  padding: 10px;
  max-width: 800px !important;
}

.title_course {
  padding: 5px;
  cursor: pointer;
  display: flex;

}

.title_course:hover {
  box-shadow: 2px 2px 10px #066293;
}

.checked {
  border-radius: 5px;
  box-shadow: 2px 2px 10px green;
}

.courses_block {
  margin: auto;
  justify-content: center;

  .card {
    padding: 1%;
    cursor: pointer;
    margin: 4px;
    border: 1px solid black;
  }

  .ccard {
    padding: 1%;
    cursor: pointer;
    margin: 4px;
    border: 1px solid black;
  }
}

.card:hover {
  //border: 1px solid black;
  box-shadow: 2px 2px 10px #066293;
  //font-size: 17px;
}

.card-title {
  text-align: center;
  text-transform: uppercase;

}

.modal-courses {
  margin: 2px;
}
</style>
