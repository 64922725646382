import Vue from 'vue'
import VueRouter from 'vue-router'
import OrderDefault from "@/views/OrderDefault";
import OrderTN from "@/views/OrderTN";
import OrderRN from "@/views/OrderRN";
import OrderNN from "@/views/OrderNN";
import OrderExperements from "@/views/OrderExperements";
import OrderLu1 from "@/views/OrderLu1";
import OrderLu2 from "@/views/OrderLu2";
import OrderRNT from "../views/OrderRNT";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'OrderDefault',
        component: OrderDefault
    },
    {
        path: '/tn',
        name: 'OrderTN',
        component: OrderTN
    },
    {
        path: '/transneft',
        name: 'OrderTN',
        component: OrderTN
    },
    {
        path: '/rnt',
        name: 'OrderRNT',
        component: OrderRNT
    },
    {
        path: '/rn',
        name: 'OrderRN',
        component: OrderRN
    },
    {
        path: '/lu',
        name: 'OrderNN',
        component: OrderNN
    },
    {
        path: '/lu1',
        name: 'OrderLu1',
        component: OrderLu1
    },
    {
        path: '/lu2',
        name: 'OrderLu2',
        component: OrderLu2
    },
    {
        path: '/ex',
        name: 'OrderExperements',
        component: OrderExperements
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
