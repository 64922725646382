<template>
  <b-modal :id="'ic' + course.id" size="xl" centered hide-footer :title="course.title">
    <div style="text-align: center">Выберите вариант обучения</div>
    <div class="row" style="width: 100%;margin: auto; justify-content: center">
      <div
          style="text-align: center" class="col-sm-6">
        <b-card @click="selectedCourse"
                title="Обучение на площадке Moodle"
                img-src="https://bmipk.ru/img/moodle.webp"
                img-alt="Image"
                img-top
                tag="article"
                style="max-width: 20rem; margin: auto"
                class="mb-2"
        >
          <b-card-text>
            <b-button variant="outline-success">Выбрать</b-button>
            <div>После регистрации на нашем сайте на Ваш e-mail придет письмо, содержащее ссылку, логин и пароль для
              входа в
              виртуальную обучающую среду института, где обучение происходит при постоянном подключении к сети Интернет,
              то есть онлайн, через браузер (Яндекс, Chrome, Edge или др.). Информация о Вашем обучении поступит к нам
              автоматически.
            </div>

          </b-card-text>
        </b-card>
      </div>
      <div style="text-align: center"
          v-if="course.case_c_sdo.length" class="col-sm-6">
        <b-card @click="selectedCase"
                title="Обучение кейсовым методом"
                 img-src="https://bmipk.ru/img/case.webp"
                img-alt="Image"
                img-top
                tag="article"
                style="max-width: 20rem; margin: auto"
                class="mb-2"
        >
          <b-card-text>
            <b-button variant="outline-success">Выбрать</b-button>
            <div>После регистрации на нашем сайте на Ваш e-mail придет письмо со ссылкой на облачный сервис, где Вы
              можете
              скачать все материалы учебного курса и продолжить работу без доступа к сети Интернет. После их изучения
              необходимо ответить на вопросы экзаменационного билета и переслать его скан на электронную почту,
              указанную
              в инструкции к курсу.
            </div>
          </b-card-text>

        </b-card>
      </div>
    </div>
  </b-modal>
</template>
<script>

export default {
  name: "itemCourse",
  props: ['course'],
  methods: {
    selectedCourse() {
      let data = {
        type: 'course',
        title: this.course.title,
        id: this.course.course_sdo.id,
        id_f: this.course.id,
        documents: {
          snils: this.course.course_sdo.is_snils,
          document_of_education: this.course.course_sdo.document_of_education,
        }


      }
      this.$emit('respSelected', data)
      this.$bvModal.hide('ic' + this.course.id)
    },
    selectedCase() {
      let data = {
        type: 'case',
        title: this.course.case_c_sdo[0].title,
        id_c: this.course.case_c_sdo[0].case_sdo.id,
        documents: {
          snils: this.course.course_sdo.is_snils,
          document_of_education: this.course.course_sdo.document_of_education,
        }
      }
      this.$emit('respSelected', data)
      this.$bvModal.hide('ic' + this.course.id)
    }
  }
}
</script>

<style scoped>

</style>