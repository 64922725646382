<template>
  <div>
    <div v-if="levelNext.level2.length > 1" class="level2">
      <b-row class="my-1">
        <b-col sm="4">
          <label><span class="required">{{ levelNext.level2[0].description }}*</span></label>
        </b-col>
        <b-col>
          <b-form-select v-model="selected.level3" class="form-control">
            <b-form-select-option disabled value=null>Выберите из предложенных вариантов</b-form-select-option>
            <b-form-select-option v-for="(item, index) in levelNext.level2" :key="index"
                                  :value="item">{{ item.title }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>
    </div>
    <div v-if="levelNext.level3.length > 1" class="level3">
      <b-row class="my-1">
        <b-col sm="4">
          <label><span class="required">{{ levelNext.level3[0].description }}*</span></label>
        </b-col>
        <b-col>
          <b-form-select v-model="selected.level4" class="form-control">
            <b-form-select-option disabled value=null>Выберите из предложенных вариантов</b-form-select-option>
            <b-form-select-option v-for="(item, index) in levelNext.level3" :key="index"
                                  :value="item">{{ item.title }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>
    </div>
    <div v-if="levelNext.level4.length > 1" class="level4">
      <b-row class="my-1">
        <b-col sm="4">
          <label><span class="required">{{ levelNext.level4[0].description }}*</span></label>
        </b-col>
        <b-col>
          <b-form-select v-model="selected.level5" class="form-control">
            <b-form-select-option disabled value=null>Выберите из предложенных вариантов</b-form-select-option>
            <b-form-select-option v-for="(item, index) in levelNext.level4" :key="index"
                                  :value="item">{{ item.title }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>
    </div>
    <div v-if="levelNext.level5.length > 1" class="level5">
      <b-row class="my-1">
        <b-col sm="4">
          <label><span class="required">{{ levelNext.level5[0].description }}*</span></label>
        </b-col>
        <b-col>
          <b-form-select v-model="selected.level6" class="form-control">
            <b-form-select-option disabled value=null>Выберите из предложенных вариантов</b-form-select-option>
            <b-form-select-option v-for="(item, index) in levelNext.level5" :key="index"
                                  :value="item">{{ item.title }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>
    </div>
    <div v-if="levelNext.level6.length > 1" class="level6">
      <b-row class="my-1">
        <b-col sm="4">
          <label><span class="required">{{ levelNext.level6[0].description }}*</span></label>
        </b-col>
        <b-col>
          <b-form-select v-model="selected.level7" class="form-control">
            <b-form-select-option disabled value=null>Выберите из предложенных вариантов</b-form-select-option>
            <b-form-select-option v-for="(item, index) in levelNext.level6" :key="index"
                                  :value="item">{{ item.title }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>
    </div>
    <div v-if="levelNext.level7.length > 1" class="level7">
      <b-row class="my-1">
        <b-col sm="4">
          <label><span class="required">{{ levelNext.level7[0].description }}*</span></label>
        </b-col>
        <b-col>
          <b-form-select v-model="levelNext.courses" class="form-control">
            <b-form-select-option disabled :value="{}">Выберите из предложенных вариантов</b-form-select-option>
            <b-form-select-option v-for="(item, index) in levelNext.level7" :key="index"
                                  :value="item">{{ item.title }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "level1",
  props: ['level', 'id_company'],
  data() {
    return {
      levelNext: {
        level1: [],
        level2: [],
        level3: [],
        level4: [],
        level5: [],
        level6: [],
        level7: [],
        courses: {}
      },
      selected: {
        level1: null,
        level2: null,
        level3: null,
        level4: null,
        level5: null,
        level6: null,
        level7: null,

      },
    }
  },
  watch: {
    'levelNext.level2': function () {
      if (this.levelNext.level2.length === 1)
        this.selected.level3 = this.levelNext.level2[0]
    },
    'levelNext.level3': function () {
      if (this.levelNext.level3.length === 1)
        this.selected.level4 = this.levelNext.level3[0]
    },
    'levelNext.level4': function () {
      if (this.levelNext.level4.length === 1)
        this.selected.level5 = this.levelNext.level4[0]
    },
    'levelNext.level5': function () {
      if (this.levelNext.level5.length === 1)
        this.selected.level6 = this.levelNext.level5[0]
    },
    'levelNext.level6': function () {
      if (this.levelNext.level6.length === 1)
        this.selected.level7 = this.levelNext.level6[0]
    },
    'levelNext.level7': function () {
      if (this.levelNext.level7.length === 1)
        this.levelNext.courses = this.levelNext.level7[0]
    },

    'selected.level3': function () {
      this.getLevel(3, this.selected.level3.id)
    },
    'selected.level4': function () {
      this.getLevel(4, this.selected.level4.id)
    },
    'selected.level5': function () {
      this.getLevel(5, this.selected.level5.id)
    },
    'selected.level6': function () {
      this.getLevel(6, this.selected.level6.id)
    },
    'selected.level7': function () {
      if (this.selected.level7 != null)
        this.getLevel(7, this.selected.level7.id)
    },
    'levelNext.courses': function () {
      this.$emit('returnCourses', this.levelNext.courses)
    }
  },
  methods: {
    ...mapGetters(['getUrl']),
    async getLevel(level, levelUp) {
      let url = this.getUrl() + 'forms/level' + level + '-form/?id=&level_up=' + levelUp
      await fetch(url, {
        method: "get",
        credentials: "same-origin",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      })
          .then(response => response.json())
          .then(response => {
                // this.scelet = response
                if (level == 1)
                  this.levelNext.level1 = response
                if (level == 2)
                  this.levelNext.level2 = response
                if (level == 3)
                  this.levelNext.level3 = response
                if (level == 4)
                  this.levelNext.level4 = response
                if (level == 5) {
                  this.selected.level6 = null
                  this.levelNext.level6 = []
                  this.selected.level7 = null
                  this.levelNext.level5 = response
                }
                if (level == 6) {
                  this.selected.level7 = null
                  this.levelNext.level7 = []
                  this.levelNext.level6 = response
                }
                if (level == 7) {
                  // this.selected.level7 = null
                  this.levelNext.courses = {}
                  this.levelNext.level7 = response
                }
              }
          )
    },
    async distributionChoice() {
      this.getLevel(2, this.id_company)
    }
  },
  created() {
    this.distributionChoice()


  }
}
</script>


<style scoped>

</style>