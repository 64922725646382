<template>
  <section class="container-fluid">
    <div class="section_form">
      <b-form @submit="onSubmit" class="form_order" inline>
        <div class="order_data" inline>
          <!--          <div class="lastname">-->
          <!--            <b-row class="my-1">-->
          <!--              <b-col sm="4">-->
          <!--                <label>Фамилия<span class="required">*</span></label>-->
          <!--              </b-col>-->
          <!--              <b-col sm="8">-->
          <!--                <b-form-input-->
          <!--                    type="text"-->
          <!--                    maxlength="70"-->
          <!--                    v-model="order.lastname"-->
          <!--                    required-->
          <!--                ></b-form-input>-->
          <!--              </b-col>-->
          <!--            </b-row>-->
          <!--          </div>-->
          <!--          <div class="email">-->
          <!--            <b-row class="my-1">-->
          <!--              <b-col sm="4">-->
          <!--                <label>Email<span class="required">*</span></label>-->
          <!--              </b-col>-->
          <!--              <b-col sm="8">-->
          <!--                <b-form-input-->
          <!--                    validated-->
          <!--                    type="email"-->
          <!--                    v-model="order.email"-->
          <!--                    required-->
          <!--                ></b-form-input>-->

          <!--              </b-col>-->
          <!--            </b-row>-->
          <!--          </div>-->
          <div class="company">
            <b-row class="my-1">
              <b-col sm="4">
                <label>Организация<span class="required">*</span></label>
              </b-col>
              <b-col sm="8">
                <div style="position: relative">
                  <b-form-input class="cover"
                                v-model="check.company_tmp"
                                list="input-company"
                                required
                  ></b-form-input>
                  <b-form-datalist id="input-company" :options="options.company_tn"></b-form-datalist>
                  <input v-if="!validCompany()" v-model="order.company" oninvalid="this.setCustomValidity('sdfsdff')"
                         type="text"
                         required/>
                  <input v-else v-model="order.company" disabled oninvalid="this.setCustomValidity('')" type="text" required/>

                </div>
                <!--                <div style="position: relative">-->
                <!--                  <div style="width: 100px; height: 100px; background: #2c3e50;position: relative"></div>-->
                <!--                  <div style="width: 20px; height: 20px; background: red;position: absolute; top: 0"></div>-->
                <!--                </div>-->


                <!--                <b-form-select-->
                <!--                    class="form-control"-->
                <!--                    v-model="order.company"-->
                <!--                    :options="options.company_tn"-->
                <!--                    required-->
                <!--                >-->
                <!--                  <template #first>-->
                <!--                    <b-form-select-option :value="null" disabled>&#45;&#45; Выберите из предложенных вариантов &#45;&#45;-->
                <!--                    </b-form-select-option>-->
                <!--                  </template>-->
                <!--                </b-form-select>-->
              </b-col>
            </b-row>
          </div>
          <div class="company_filial" v-if="options.filials_tn.length">
            <b-row class="my-1">
              <b-col sm="4">
                <label>Филиал<span class="required">*</span></label>
              </b-col>
              <b-col sm="8">
                <b-form-select
                    class="form-control"
                    v-model="order.company_filial"
                    :options="options.filials_tn"
                    required
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Выберите из предложенных вариантов --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-col>
            </b-row>
          </div>
        </div>
        <b-button type="submit" variant="primary">Отправить</b-button>
      </b-form>
    </div>
    <hr>

  </section>
</template>

<script>
import {mask} from 'vue-the-mask'
import Level1 from "@/components/orderTN/level1";
import ItemCourse from "@/components/orderTN/itemCourse";
import Courses from "@/components/orderTN/courses";
import {required, minLength, between} from 'vuelidate/lib/validators'

export default {
  name: "OrderExperements",
  components: {Courses, ItemCourse, Level1},
  directives: {mask},
  data() {
    return {
      order: {
        firstname: '',
        lastname: '',
        patronymic: '',
        age: '',
        phone: '',
        email: '',
        snils: '',
        education: null,
        place_of_study: '',
        add_education: '',
        address: '',
        company: '',
        work_post: '',
        add_inform: '',
        courses: [],
        cases: [],
        company_filial: null
      },
      options: {
        education: [
          {value: '9 классов', text: '9 классов'},
          {value: '11 классов', text: '11 классов'},
          {value: 'Начальное профессиональное', text: 'Начальное профессиональное'},
          {value: 'Среднее профессиональное', text: 'Среднее профессиональное'},
          {value: 'Высшее', text: 'Высшее'},

        ],
        company_tn: [
          {
            value: 'АО «Связьтранснефть»', text: 'АО «Связьтранснефть»',
            filials: [
              {value: 'Аппарат управления', text: 'Аппарат управления'},
              {value: 'Прибайкальское ПТУС', text: 'Прибайкальское ПТУС'},
              {value: 'Прииртышское  ПТУС', text: 'Прииртышское  ПТУС'},
              {value: 'Среднеобское  ПТУС', text: 'Среднеобское  ПТУС'},
              {value: 'Дальневосточное  ПТУС', text: 'Дальневосточное  ПТУС'},
              {value: 'Западно-Сибирское  ПТУС', text: 'Западно-Сибирское  ПТУС'},
              {value: 'Северо-Кавказское ПТУС', text: 'Северо-Кавказское ПТУС'},
              {value: 'Сибирское ПТУС', text: 'Сибирское ПТУС'},
              {value: 'Верхневолжское  ПТУС', text: 'Верхневолжское  ПТУС'},
              {value: 'Волго-Камское  ПТУС', text: 'Волго-Камское  ПТУС'},
              {value: 'Северное  ПТУС', text: 'Северное  ПТУС'},
              {value: 'Средневолжское ПТУС', text: 'Средневолжское ПТУС'},
              {value: 'Уральское ПТУС', text: 'Уральское ПТУС'},

            ]
          },
          {value: 'ООО «Транснефть - ТСД»', text: 'ООО «Транснефть - ТСД»'},
          {
            value: 'АО «Транснефть - Урал»', text: 'АО «Транснефть - Урал»',
            filials: [
              {value: 'Аппарат управления', text: 'Аппарат управления'},
              {value: 'Туймазинское НУ', text: 'Туймазинское НУ'},
              {value: 'Арланское НУ', text: 'Арланское НУ'},
              {value: 'Черкасское НУ', text: 'Черкасское НУ'},
              {value: 'Челябинское НУ', text: 'Челябинское НУ'},
              {value: 'Курганское НУ', text: 'Курганское НУ'},
              {value: 'СУПЛАВ', text: 'СУПЛАВ'},
              {value: 'ЛПДС «Петропавловск»', text: 'ЛПДС "Петропавловск"'},
            ]
          },
          {value: 'АО «Транснефть – Метрология»', text: 'АО «Транснефть – Метрология»'},
        ],
        filials_tn: [],
        courses: {},
      },
      selectOptions: {
        level1: null,
        level2: null,
        level3: null,
        level4: null,
        level5: null,
        level6: null,
        level7: null,
      },
      alert: {
        dismissSecs: 3,
        dismissCountDown: 0,
        showDismissibleAlert: false
      },
      file1: null,
      file2: null,
      status: false,
      text: '',
      confidentiality: false,
      duties: false,
      check: {
        is_company: true,
        checkDiplomFamyli: true,
        company_tmp: ''
      },
      scelet: [],
      trigger: {
        deleteCourse: null,
        deleteCases: null
      }
    }
  },
  watch: {
    'check.company_tmp': function () {
      if (this.options.company_tn.filter(elem => elem.value === this.check.company_tmp).length) {
        this.order.company = this.check.company_tmp
      } else {
        this.order.company = ''
      }
    },
    'order.company': function () {
      if (this.options.company_tn.filter(elem => elem.value === this.order.company).length) {
        let company = this.options.company_tn.find(elem => elem.value === this.order.company)
        if (company.filials)
          this.options.filials_tn = company.filials
        else {
          this.order.company_filial = ''
          this.options.filials_tn = []
        }
      } else {

      }
    }
  },
  methods: {
    validCompany() {
      console.log(this.options.company_tn.filter(elem => elem.value === this.check.company_tmp).length > 0)
      return this.options.company_tn.filter(elem => elem.value === this.check.company_tmp).length > 0
    },
    onSubmit(event) {
      event.preventDefault()
      this.postOrder()
    },
    async postOrder() {
      console.log('post')
    },
  },
}
</script>

<style scoped lang="scss">
//.res_cover {
//  position: absolute;
//  top: 0;
//  z-index: 0;
//  opacity: 0;
//
//  //opacity: 0;
//}
//
.cover {
  position: relative;
  z-index: 1;

}

.section_form {
  height: 500px;
}

.file {
  text-align: left;
}

@media (max-width: 600px) {
  .file {
    text-align: center;
  }
}


.selected_courses {
  border-radius: 5px;
  box-shadow: 2px 2px 10px #066293;
  text-align: left;
  padding: 2px 5%;

  .selected_item {
    margin: 5px auto;
  }
}

.dynamic_dields {
  margin-top: 15px;
}

.courses {
  margin-top: 15px;
}

.required {
  color: red;
}

.container-courses {
  width: 100%;
  margin: 10px auto;
}

.confidentiality {
  max-width: 1200px;
  margin: auto;
  text-align: left;
}

.is-invalid {
  //border-color: #066293!important;
  //border-color: #066293!important;
  //box-shadow: 0 0 3px #dc3545;
}
</style>
