<template>
  <div style="width: 100%">
    <div id="app">
      <div class="container-fluid">
        <header-app/>
        <div style="max-width: 1200px;  margin: auto;">
          <router-view/>
        </div>
        <footer-app/>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#app {
  font-family: "PTSans", "xTfgNruv", "Trebuchet MS", "Tahoma", "Verdana", "Arial", "Helvetica", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.container {
  display: flex;

  flex-direction: column;
  justify-content: space-between;
}

section {
  margin: auto;

  .section_top {
    h2 {
      margin-top: 20px;
    }

  }
}

header, footer {
  //border: 1px solid black;
  background: #066293;
  color: white;
}
.selected_item{
  display: flex;
  justify-content: space-between;
}
.delete-selected-course{
  svg{
    cursor: pointer;
  }
}
</style>
<script>

import HeaderApp from "@/views/modulsApp/headerApp";
import FooterApp from "@/views/modulsApp/footerApp";

export default {
  components: {FooterApp, HeaderApp}
}
</script>