<template>
  <footer class="row" style="margin-top: 10px">
    <div class="footer_left col-sm-6 col-lg-4">

      <div class="footer_left_contact">
        450006, Башкортостан, Уфа, ул. Революционная 55, 3-5 -этажи <br>
        Тел.: (347) 250-96-19, (347) 251-17-75, факс: 272-35-43 <br>
        E-mail: <a href="mailto:client@bmipk.ru">client@bmipk.ru</a><br>
      </div>
      <div class="footer_left_copyright">
        © 1967-2021 ЧОУ «Межотраслевой институт»
      </div>
    </div>
    <div class="footer_midl col-sm-6 col-lg-4">
      <div class="header_info_social col-sm">
        <a style="text-decoration: none" href="https://vk.com/bmipk" target="_blank">
          <img src="https://bmipk.ru/bitrix/templates/bmipk/vk-512.png" width="30px" height="auto"
               style="margin-right:4px;">
        </a>
<!--        <a style="text-decoration: none" href="https://www.instagram.com/interindustry_institute/" target="_blank">-->
<!--          <img src="https://bmipk.ru/bitrix/templates/bmipk/social-instagram-new-circle-512.png" width="31px"-->
<!--               height="auto">-->
<!--        </a>-->
      </div>
    </div>
    <div class="footer_right col-sm-6 col-lg-4"></div>
  </footer>
</template>

<script>
export default {
  name: "footerApp"
}
</script>

<style scoped>
footer {
  padding: 20px;
  font-size: 12px;
}
</style>