<template>
  <section class="container-fluid">
    <div class="section_top">
      <h2>Заявка на дистанционное обучение</h2>
      <h2>для организации ООО "Лукойл-Уралнефтепродукт"</h2>
      <hr>
    </div>
    <div class="section_form">
      <div style="text-align: center; font-size: 12px; color: gray; margin-bottom: 20px">Поля отмеченные <span
          class="required">*</span> обязательны для заполнения
      </div>
      <b-form @submit="onSubmit" class="form_order" inline>
        <div class="order_data" inline>
          <div class="lastname">
            <b-row class="my-1">
              <b-col sm="4">
                <label>Фамилия<span class="required">*</span></label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                    type="text"
                    maxlength="70"
                    v-model="order.lastname"
                    required
                ></b-form-input>
              </b-col>
            </b-row>
          </div>
          <div class="firstname">
            <b-row class="my-1">
              <b-col sm="4">
                <label>Имя<span class="required">*</span></label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                    type="text"
                    maxlength="70"
                    v-model="order.firstname"
                    required
                ></b-form-input>
              </b-col>
            </b-row>
          </div>
          <div class="patronymic">
            <b-row class="my-1">
              <b-col sm="4">
                <label>Отчество<span class="required">*</span></label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                    type="text"
                    maxlength="70"
                    v-model="order.patronymic"
                    required
                ></b-form-input>
              </b-col>
            </b-row>
          </div>
          <div class="age">
            <b-row class="my-1">
              <b-col sm="4">
                <label>Дата рождения<span class="required">*</span></label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                    type="date"
                    v-model="order.age"
                    required
                ></b-form-input>
              </b-col>
            </b-row>
          </div>
          <div class="phone">
            <b-row class="my-1">
              <b-col sm="4">
                <label>Контактный телефон<span class="required">*</span></label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                    type="text"
                    maxlength="70"
                    v-model="order.phone"
                    required
                ></b-form-input>
              </b-col>
            </b-row>
          </div>
          <div class="email">
            <b-row class="my-1">
              <b-col sm="4">
                <label>Email<span class="required">*</span></label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                    type="email"
                    v-model="order.email"
                    required
                ></b-form-input>
              </b-col>
            </b-row>
          </div>
          <div class="address">
            <b-row class="my-1">
              <b-col sm="4">
                <label>Адрес<span class="required">*</span></label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                    type="text"
                    maxlength="190"
                    v-model="order.address"
                    required
                ></b-form-input>
              </b-col>
            </b-row>
          </div>
          <div class="education">
            <b-row class="my-1">
              <b-col sm="4">
                <label>Образование<span class="required">*</span></label>
              </b-col>
              <b-col>
                <b-form-select
                    class="form-control"
                    v-model="order.education"
                    :options="options.education"
                    required
                ></b-form-select>
              </b-col>
            </b-row>
          </div>
          <!--          <div class="add_education">-->
          <!--            <b-row class="my-1">-->
          <!--              <b-col sm="4">-->
          <!--                <label>Дополнительное образование</label>-->
          <!--              </b-col>-->
          <!--              <b-col sm="8">-->
          <!--                <b-form-input-->
          <!--                    type="text"-->
          <!--                    maxlength="190"-->
          <!--                    v-model="order.add_education"-->
          <!--                ></b-form-input>-->
          <!--              </b-col>-->
          <!--            </b-row>-->
          <!--          </div>-->
          <div class="company">
            <b-row class="my-1">
              <b-col sm="4">
                <label>Организация<span class="required">*</span></label>
              </b-col>
              <b-col sm="8">
                <b-form-select
                    class="form-control"
                    disabled
                    v-model="order.company"
                    :options="options.company_tn"
                    required
                ></b-form-select>
              </b-col>
            </b-row>
          </div>
          <div class="work_post">
            <b-row class="my-1">
              <b-col sm="4">
                <label>Должность<span class="required">*</span></label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                    type="text"
                    maxlength="70"
                    v-model="order.work_post"
                    required
                ></b-form-input>
              </b-col>
            </b-row>
          </div>
          <div class="add_inform">
            <b-row class="my-1">
              <b-col sm="4">
                <label>Дополнительная информация</label>
              </b-col>
              <b-col sm="8">
                <b-form-textarea
                    v-model="order.add_inform"
                    rows="3"
                    max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
          </div>
          <div ref="markist">
            <b-alert
                :show="alert.dismissCountDown"
                variant="warning"
                @dismissed="alert.dismissCountDown=0"
            >
              Выберите хотя бы один курс
            </b-alert>
          </div>
        </div>
        <br>
        <div class="courses-lu" style="text-align: left">
          <b-form-checkbox-group v-model="order.courses" size="lg">
            <b-form-checkbox v-for="(item, index) in options.courses_lu" :key="index"
                             :value="item">{{ item.title }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </div>
        <div class="dynamic_dields">
          <div class="snils" v-if="checkSnils()">
            <b-row class="my-1">
              <b-col sm="4">
                <label>СНИЛС<span class="required">*</span>
                  <b-icon
                      v-b-popover.hover.top="'Постановление Правительства РФ от 31 мая 2021г. N825 «О федеральной информационной системе «Федеральный реестр сведений о документах об образовании и (или) о квалификации, документах об обучении»'"
                      title="Popover Title"
                      icon="question-circle-fill" aria-label="Help"></b-icon>
                </label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                    v-mask="'###-###-### ##'"
                    type="text"
                    maxlength="14"
                    v-model="order.snils"
                    required
                ></b-form-input>
              </b-col>
            </b-row>
          </div>
          <div class="file_education" v-if="checkEducation()">
            <b-row class="my-1">
              <b-col sm="4">
                <label>Документ об образовании:<span class="required">*</span>
                  <!--                  <b-icon v-b-popover.hover.top="'I am popover directive content!'" title="Popover Title"-->
                  <!--                          icon="question-circle-fill" aria-label="Help"></b-icon>-->
                </label>
              </b-col>
              <b-col sm="8" class="file">
                <b-form-file
                    v-model="file1"
                    :state="Boolean(file1)"
                    placeholder=""
                    required
                ></b-form-file>
              </b-col>
              <b-col sm="12" style="font-size: 12px">Диплом должен быть в развернутом виде. В формате JPG, PNG, PDF
              </b-col>
            </b-row>
          </div>
        </div>
        <hr>
        <div class="confidentiality">
          <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="confidentiality" required>
              <label class="form-check-label" for="confidentiality" style="color: red">
                <div>Согласно требованиям <a href="http://www.consultant.ru/document/cons_doc_LAW_61801/"> Федерального
                  закона от 27 июля 2006 г. №152 -ФЗ "О персональных данных"</a> даю свое
                  согласие на обработку указанных персональных данных, для оформления учебной документации. В случае
                  неправомерного использования предоставленных данных соглашение отзывается письменным заявлением
                  субьекта
                  персональных данных. Данное соглашение действует с даты подписания сроком на 5 (пять) лет.
                </div>
              </label>
            </div>
          </div>
          <br>
          <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="duties" required>
              <label class="form-check-label" for="duties" style="color: red">
                <div>Я, обязуюсь не передавать логин и пароль третьим лицам.</div>
              </label>
            </div>
          </div>
        </div>
        <br/>
        <b-button :disabled="lock_btn" type="submit" variant="primary">Отправить</b-button>
      </b-form>
      <b-modal id="modal-info" centered no-close-on-backdrop no-close-on-esc title="" hide-header>
        <template #modal-footer>
          <b-button v-if="status" @click="reloadPage()">ОК</b-button>
        </template>
        <div v-if="status" style="width: 100%">
          <div style="text-align: center">
            <img style="margin: 0 auto" src="https://cf.reversegif.ru/gif_source/wF9Z2dNjo8UI.gif" width="50%">
          </div>
          <div class="title_info_a">{{ text }}</div>
        </div>
        <div v-else style="width: 100%">
          <div class="title_info">ОШИБКА!</div>
          {{ text }}
        </div>
      </b-modal>
    </div>
  </section>
</template>

<script>
import {mask} from 'vue-the-mask'
import {mapGetters} from "vuex";
import Level1 from "@/components/orderTN/level1";
import ItemCourse from "@/components/orderTN/itemCourse";
import Courses from "@/components/orderTN/courses";

export default {
  name: "OrderNN",
  components: {Courses, ItemCourse, Level1},
  directives: {mask},
  data() {
    return {
      order: {
        firstname: '',
        lastname: '',
        patronymic: '',
        age: '',
        phone: '',
        email: '',
        snils: '',
        education: null,
        place_of_study: '',
        add_education: '',
        address: '',
        company: 'ООО "Лукойл-Уралнефтепродукт"',
        work_post: '',
        add_inform: '',
        courses: [],
        cases: [],
      },
      options: {
        education: [
          {value: null, text: 'Выберите из предложенных вариантов'},
          {value: '9 классов', text: '9 классов'},
          {value: '11 классов', text: '11 классов'},
          {value: 'Начальное профессиональное', text: 'Начальное профессиональное'},
          {value: 'Среднее профессиональное', text: 'Среднее профессиональное'},
          {value: 'Высшее', text: 'Высшее'},

        ],
        company_tn: [
          {value: 'ООО "Лукойл-Уралнефтепродукт"', text: 'ООО "Лукойл-Уралнефтепродукт"'},
        ],
        courses: {
          "courses": [],
          "cases": [],
          "complex_c_c": [],
        },
        courses_lu: [
          {
            "type": "course",
            "title": "Оказание первой помощи пострадавшим",
            "id": 212,
            "documents": {"snils": false, "document_of_education": false}
          },
          {
            "type": "course",
            "title": "Использование (применение) средств индивидуальной защиты",
            "id": 214,
            "documents": {"snils": false, "document_of_education": false}
          }
        ]

      },
      selectOptions: {
        level1: null,
        level2: null,
        level3: null,
        level4: null,
        level5: null,
        level6: null,
        level7: null,
      },
      alert: {
        dismissSecs: 3,
        dismissCountDown: 0,
        showDismissibleAlert: false
      },
      file1: null,
      file2: null,
      status: false,
      text: '',
      confidentiality: false,
      duties: false,
      check: {
        is_company: true,
        checkDiplomFamyli: true
      },
      scelet: [],
      trigger: {
        deleteCourse: null,
        deleteCases: null
      },
      lock_btn: false
    }
  },
  methods: {
    ...
        mapGetters(['getUrl']),
    deleteSelectedCourse(course) {
      this.trigger.deleteCourse = course
    }
    ,
    deleteSelectedCases(cases) {
      this.trigger.deleteCases = cases
    }
    ,
    getCourses(data) {
      this.options.courses = data
    }
    ,
    onSubmit(event) {
      if (this.order.courses.length || this.order.cases.length) {
        event.preventDefault()
        this.postOrder()
      } else {
        event.preventDefault()
        let top = window.scrollY + (this.$refs['markist']).getBoundingClientRect().y;
        window.scrollTo(0, top)
        this.showAlert()
      }
    }
    ,
    async postOrder() {
      this.lock_btn = true
      let formData = new FormData()
      let post_data = {...this.order}
      post_data.courses = []
      post_data.cases = []
      for (let i = 0; i < this.order.courses.length; i++) {
        post_data.courses.push({id: this.order.courses[i].id})
      }
      for (let i = 0; i < this.order.cases.length; i++) {
        post_data.cases.push({id_c: this.order.cases[i].id_c})
      }
      formData.append('file1', this.file1)
      formData.append('order', JSON.stringify(post_data))

      let res = await fetch('https://csdo.bmipk.ru/csdo/order/post/', {
        method: 'POST',
        headers: {},
        body: formData
      });
      let result = await res.json();
      if (result.status) {
        this.status = result.status
        this.order = {
          firstname: '',
          lastname: '',
          patronymic: '',
          age: '',
          phone: '',
          email: '',
          snils: '',
          education: null,
          place_of_study: '',
          add_education: '',
          address: '',
          work_post: '',
          add_inform: '',
          courses: [],
          cases: [],
        }
        this.confidentiality = false
        this.duties = false
        this.$bvModal.show('modal-info')
        this.file = null
        this.text = result.message
        this.options.courses = {}
      } else if (!result.status) {
        this.status = false
        this.text = result.message
        this.$bvModal.show('modal-info')
      } else {
        this.status = result.status
        this.text = ''
        this.$bvModal.show('modal-info')
        this.file = null
      }
      this.lock_btn = false
    }
    ,
    checkSnils() {
      for (let i = 0; i < this.order.courses.length; i++)
        if (this.order.courses[i].documents.snils)
          return true
      return false
    }
    ,
    checkEducation() {
      for (let i = 0; i < this.order.courses.length; i++)
        if (this.order.courses[i].documents.document_of_education)
          return true
      return false
    }
    ,
    countDownChanged(dismissCountDown) {
      this.alert.dismissCountDown = dismissCountDown
    }
    ,
    showAlert() {
      this.alert.dismissCountDown = this.alert.dismissSecs
    }
    ,
    reloadPage() {
      window.location.reload()
    }
    ,
    fillCoursesAndCases(data) {
      this.order.courses = data.courses
      this.order.cases = data.cases
    }
    ,
  }
  ,

}
</script>

<style lang="scss">
.file {
  text-align: left;
}

@media (max-width: 600px) {
  .file {
    text-align: center;
  }
}


.selected_courses {
  border-radius: 5px;
  box-shadow: 2px 2px 10px #066293;
  text-align: left;
  padding: 2px 5%;

  .selected_item {
    margin: 5px auto;
  }
}

.dynamic_dields {
  margin-top: 15px;
}

.courses {
  margin-top: 15px;
}

.required {
  color: red;
}

.container-courses {
  width: 100%;
  margin: 10px auto;
}

.confidentiality {
  max-width: 1200px;
  margin: auto;
  text-align: left;
}


.courses-lu{
  input[type="checkbox"]{
    width: 18px;
    height: 18px;
    margin: 7px;
  }
}
</style>
