<template>
  <div class="courses_block row">
    <br>
    <div v-if="courses.complex_c_c">
      <div v-if="courses.complex_c_c.length" class="search_course">
        <div style="white-space: nowrap; margin-right: 10px">Поиск по курсам</div>
        <b-form-input list="input-list" v-model="search"></b-form-input>
        <b-form-datalist id="input-list" :options="searchData()"></b-form-datalist>
      </div>
    </div>
    <hr>
    <div v-for="(item,index) in courses.complex_c_c" :key="index" class="card col-sm-3"
         v-b-modal="'modal-courses' + item.id" @click="sortByComplexes(item.courses)" :class="searchSetClass(item.id)">
      <div class="card_content">
        <div class="card-title">{{ item.title }}</div>
      </div>
      <b-modal class="modal-courses" :id="'modal-courses' + item.id" size="xl" ok-only centered>
        <template #modal-header>
          <div class="card-title">{{ item.title }}</div>
        </template>
        <div>
          <div v-for="(course,index) in coursesSolo" :key="index + 'course'">
            <div @click="openModal(course)"
                 class="title_course"
                 :class="{'checked': checkSelected(course)}"
            >
              <b-icon v-if="checkSelected(course)" icon="check-all" font-scale="1.5" variant="success"></b-icon>
              <div :class="searchSetClassForItem(course.title)">{{course.course_sdo.id}}{{ course.title }}</div>
            </div>
            <item-course :course="course" @respSelected="respCourseOrCase"/>
          </div>
          <div v-for="(cases,index) in item.cases" :key="index">
            <div @click="openModalCase(cases)" class="title_course" :class="{'checked': checkSelectedCases(cases)}">
              <b-icon v-if="checkSelectedCases(cases)" icon="check-all" font-scale="1.5" variant="success"></b-icon>
              <div :class="searchSetClassForItem(cases.title)">{{ cases.title }}</div>
            </div>
            <item-case :cases="cases" @respSelected="respCourseOrCase"/>
          </div>
          <div v-for="(c_course,index) in coursesComplex" :key="index + 'c_course'">
            <div class="btn_c_course" v-b-toggle="index + 'c_course'">{{ c_course.title }}
            </div>
            <b-collapse :id="index + 'c_course'">
              <div v-for="(course,index) in c_course.courses" :key="index + 'course'">
                <div @click="openModal(course)"
                     class="title_course"
                     :class="{'checked': checkSelected(course)}"
                >
                  <b-icon v-if="checkSelected(course)" icon="check-all" font-scale="1.5" variant="success"></b-icon>
                  <div :class="searchSetClassForItem(course.title)" style="display: flex">
                    <div style="width:30px;background: wheat; text-align: center">{{ course.course_sdo.id }}</div>
                    <div>{{ course.title }}</div>
                  </div>
                </div>
                <item-course :course="course" @respSelected="respCourseOrCase"/>
              </div>
            </b-collapse>
          </div>
        </div>
      </b-modal>
    </div>
    <div style="text-align: left">
      <div v-for="(course,index) in courses.courses" :key="index + 'course'">
        <div @click="selectedCourse(course)"
             class="title_course"
             :class="{'checked': checkSelected(course)}"
        >
          <b-icon class="check_icon" v-if="checkSelected(course)"
                  icon="check-square" font-scale="1.5" variant="success"></b-icon>
          <b-icon class="check_icon" v-else
                  icon="square" font-scale="1.5" variant="success"></b-icon>
          {{ course.title }}
        </div>
      </div>
      <div v-for="(cases,index) in courses.cases" :key="index">
        <div @click="selectedCase(cases)" class="title_course" :class="{'checked': checkSelectedCases(cases)}">
          <b-icon class="check_icon" v-if="checkSelectedCases(cases)"
                  icon="check-square" font-scale="1.5" variant="success"></b-icon>
          <b-icon class="check_icon" v-else
                  icon="square" font-scale="1.5" variant="success"></b-icon>
          {{ cases.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemCourse from "@/components/orderTN/itemCourse";
import ItemCase from "@/components/orderTN/itemCase";

export default {
  name: "courses",
  components: {ItemCase, ItemCourse},
  props: ['courses', 'selected_courses', 'selected_cases', 'deleteCourse', 'deleteCase'],
  data() {
    return {
      search: '',
      search_res: [],
      tmpselected_courses: [],
      coursesComplex: [],
      coursesSolo: []
    }
  },
  watch: {
    courses: function () {
      this.tmpselected_courses = []
    },
    search: function () {
      if (this.search.length) {
        this.search_res = []
        let cases = this.courses.complex_c_c.filter(elem => elem.cases.find(el => el.title.toLowerCase().includes(this.search.toLowerCase())))
        let courses = this.courses.complex_c_c.filter(elem => elem.courses.find(el => el.title.toLowerCase().includes(this.search.toLowerCase())))
        for (let i = 0; i < cases.length; i++)
          this.search_res.push(cases[i].id)
        for (let i = 0; i < courses.length; i++)
          this.search_res.push(courses[i].id)
      } else {
        this.search_res = []
      }
    },
    tmpselected_courses: function () {
      let data = {
        courses: [],
        cases: [],
      }
      for (let i = 0; i < this.tmpselected_courses.length; i++) {
        if (this.tmpselected_courses[i].type === 'case') {
          data.cases.push(this.tmpselected_courses[i])
        } else if (this.tmpselected_courses[i].type === 'course') {
          data.courses.push(this.tmpselected_courses[i])
        }
      }
      this.$emit('selected_course_and_case', data)
    },
    deleteCourse: function () {
      let course = this.tmpselected_courses.findIndex(elem => elem.id === this.deleteCourse.id)
      this.tmpselected_courses.splice(course, 1)
    },
    deleteCase: function () {
      let cases = this.tmpselected_courses.findIndex(elem => elem.id_c === this.deleteCase.id_c)
      this.tmpselected_courses.splice(cases, 1)
    }
  },
  methods: {
    openModalCase(cases) {
      if (this.tmpselected_courses.some(elem => elem.id_c == cases.case_sdo.id))
        this.tmpselected_courses.splice(this.tmpselected_courses.findIndex(elem => elem.id_c === cases.case_sdo.id), 1)
      else this.$bvModal.show('icc' + cases.id)
    },
    openModal(course) {
      if (this.tmpselected_courses.some(elem => elem.id == course.course_sdo.id))
        this.tmpselected_courses.splice(this.tmpselected_courses.findIndex(elem => elem.id === course.course_sdo.id), 1)
      else if (course.case_c_sdo.length) {
        if (this.tmpselected_courses.some(elem => elem.id_c == course.case_c_sdo[0].case_sdo.id))
          this.tmpselected_courses.splice(this.tmpselected_courses.findIndex(elem => elem.id_c === course.case_c_sdo[0].case_sdo.id), 1)
        else this.$bvModal.show('ic' + course.id)
      } else this.$bvModal.show('ic' + course.id)
    },
    respCourseOrCase(data) {
      this.tmpselected_courses.push(data)
    },
    checkSelected(course) {
      if (course.case_c_sdo.length) {
        return this.tmpselected_courses.some(elem => elem.id == course.course_sdo.id) || this.tmpselected_courses.some(elem => elem.id_c == course.case_c_sdo[0].case_sdo.id);
      } else {
        return this.tmpselected_courses.some(elem => elem.id == course.course_sdo.id)
      }

    },
    checkSelectedCases(cases) {
      return this.tmpselected_courses.some(elem => elem.id_c == cases.case_sdo.id)
    },
    selectedCourse(course) {
      if (this.tmpselected_courses.some(elem => elem.id == course.course_sdo.id))
        this.tmpselected_courses.splice(this.tmpselected_courses.findIndex(elem => elem.id === course.course_sdo.id), 1)
      else {
        let data = {
          type: 'course',
          title: course.title,
          id: course.course_sdo.id,
          documents: {
            snils: course.course_sdo.is_snils,
            document_of_education: course.course_sdo.document_of_education,
          }
        }
        this.tmpselected_courses.push(data)
      }
    },
    selectedCase(cases) {
      if (this.tmpselected_courses.some(elem => elem.id_c == cases.case_sdo.id))
        this.tmpselected_courses.splice(this.tmpselected_courses.findIndex(elem => elem.id_c === cases.case_sdo.id), 1)
      else {
        let data = {
          type: 'case',
          title: cases.title,
          id_c: cases.case_sdo.id,
          documents: {
            snils: cases.case_sdo.is_snils,
            document_of_education: cases.case_sdo.document_of_education,
          }
        }
        this.tmpselected_courses.push(data)
      }
    },
    searchData() {
      let res = []
      for (let i = 0; i < this.courses.complex_c_c.length; i++) {
        for (let j = 0; j < this.courses.complex_c_c[i].cases.length; j++) {
          res.push({
            value: this.courses.complex_c_c[i].cases[j].title,
            text: this.courses.complex_c_c[i].cases[j].title,
          })
        }
        for (let j = 0; j < this.courses.complex_c_c[i].courses.length; j++) {
          res.push({
            value: this.courses.complex_c_c[i].courses[j].title,
            text: this.courses.complex_c_c[i].courses[j].title,
          })
        }
      }
      return res
    },
    searchSetClass(complex_id) {
      if (this.search_res.length)
        if (this.search_res.includes(complex_id))
          return 'setclassSearch'
      return ''
    },
    searchSetClassForItem(course_or_case) {
      if (this.search.length)
        if (course_or_case.toLowerCase().includes(this.search.toLowerCase()))
          return 'setclassSearchForC'
      return ''
    },
    sortByComplexes(courses) {
      let tmpCoursesComplex = []
      let coursesComplex = []
      let coursesSolo = []
      for (let i = 0; i < courses.length; i++) {
        if (courses[i].course_sdo.courses_complex.length)
          for (let j = 0; j < courses[i].course_sdo.courses_complex.length; j++) {

            if (!tmpCoursesComplex.filter(elem => elem.id === courses[i].course_sdo.courses_complex[j].id).length) {

              if (courses[i].course_sdo.courses_complex[j].id !== 15 && courses[i].course_sdo.courses_complex[j].id !== 16) {
                tmpCoursesComplex.push({
                  id: courses[i].course_sdo.courses_complex[j].id,
                  title: courses[i].course_sdo.courses_complex[j].title,
                  courses: []
                })
                tmpCoursesComplex.find(elem => elem.id === courses[i].course_sdo.courses_complex[j].id).courses.push(courses[i])
              }

            } else {
              tmpCoursesComplex.find(elem => elem.id === courses[i].course_sdo.courses_complex[j].id).courses.push(courses[i])
            }
          }
        else
          coursesSolo.push(courses[i])
      }


      for (let i = 0; i < tmpCoursesComplex.length; i++) {
        if (tmpCoursesComplex[i].courses.length > 1) {
          coursesComplex.push(tmpCoursesComplex[i])
        }
      }

      this.coursesComplex = coursesComplex
      this.coursesSolo = coursesSolo
    }
  },

}
</script>

<style lang="scss">
.btn_c_course {
  text-align: left;
  font-weight: bolder;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
  background: #b9daec;

}

.check_icon {
  margin-right: 15px;
}

.setclassSearch {
  box-shadow: 2px 2px 10px green;
  animation: pulse 1s infinite;
}

.setclassSearchForC {
  font-weight: bold;
  //box-shadow: 0 4px 2px -2px green;
  padding: 2px;
  border-radius: 5px;
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 128, 0, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 128, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 128, 0, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 128, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(0, 128, 0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(0, 128, 0, 0);
    box-shadow: 0 0 0 10px rgba(0, 128, 0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 128, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 128, 0, 0);
  }
}


.search_course {
  margin: auto;
  display: flex;
  align-items: center;
  padding: 10px;
  max-width: 800px !important;
}

.title_course {
  padding: 5px;
  cursor: pointer;
  display: flex;

}

.title_course:hover {
  box-shadow: 2px 2px 10px #066293;
}

.checked {
  border-radius: 5px;
  box-shadow: 2px 2px 10px green;
}

.courses_block {
  margin: auto;
  justify-content: center;

  .card {
    padding: 1%;
    cursor: pointer;
    margin: 2px 1px;

    .card_content {

    }

  }


}

.card:hover {
  //border: 1px solid black;
  box-shadow: 2px 2px 10px #066293;
  //font-size: 17px;
}

.card-title {
  text-align: center;
  text-transform: uppercase;
}

.modal-courses {
  margin: 2px;
}
</style>
