<template>
  <header class="header row">
    <div class="header_left col-sm-6 col-lg-4 row" @click="toHome()">
      <div class="header_left_logo col-sm-6">
        <a>
          <img class="img-fluid" src="@/assets/logo.png">
        </a>
      </div>
      <div class="header_left_content col-sm-6">
        <h1 class="d-none d-sm-block">Башкирский межотраслевой институт</h1>
        <h3>охраны труда, экологии и безопасности на производстве</h3>
      </div>
    </div>
    <div class="header_midl d-none d-lg-block col-lg-4">
      <div>
        <span>{{ selected_quotes.quote }}</span>
        <div style="text-align: right; "><span :class="'author'">{{ selected_quotes.author }}</span></div>
      </div>
    </div>
    <div class="header_right col-sm-6 col-lg-4">
      <div style="margin: auto;">
        <div class="header_right_item">
          <b-icon icon="telephone"></b-icon>
          +7 <span>(347) <b>251-18-08</b></span></div>
        <div class="header_right_item">
          <b-icon icon="telephone"></b-icon>
          +7 <span>(347) <b>251-17-75</b></span></div>
        <div class="header_right_item">Уфа, ул. Революционная, 55</div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "headerApp",
  data() {
    return {
      quotes: [
        {
          author: 'Алвин Тоффлер',
          quote: 'Неграмотными людьми 21 века будут не те, кто не умеет читать и писать, а те, кто не умеет учиться и переучиваться.'
        },
        {author: 'Дадли Филд Малоун', quote: 'Нельзя научиться у того, кто постоянно с тобой соглашается.'},
        {author: 'Марк Твейн', quote: 'Образование состоит в основном из того, что мы забыли.'},
        {author: 'Клод Бернард', quote: 'Думать, что всё знаешь, останавливает тебя от того, чтобы учиться новому.'},
        {
          author: 'Гарри С. Труман',
          quote: 'В конце концов имеет значение только то, чему ты научился и что по-настоящему усвоил.'
        },
        {author: ' ', quote: 'Мудрые люди учаться на ошибках других, а глупые - на своих.'},
        {
          author: 'Конфуций',
          quote: 'Есть три метода обучения мудрости. Первый - через подражание, и он самый благородный. Второй - через повторение, и он самый простой. Третий - через опыт, и он самый горький.'
        },
        {
          author: 'Дорис Лессинг',
          quote: 'Обучение - это, на самом деле, когда ты вдруг что-то понимаешь, что ты понимал всю свою жизнь, но иначе.'
        },
        {
          author: 'Ллойд Александр',
          quote: 'Настоящие знания мы получаем, когда ищем ответ на вопрос, а не когда узнаем сам ответ.'
        },
        {
          author: 'Альберт Эйнштейн',
          quote: 'Я никогда не учу своих учеников. Я только даю им условия, при которых они могут сами учиться.'
        },
        {
          author: 'Эбигейл Адамс',
          quote: 'Обучение - это не то, что получаешь случайно. А то, к чему стремишься со страстью и делаешь с усердием.'
        },
        {author: 'Уильям А. Ворд', quote: 'Любопытство - это фитиль в свече обучения. '},
        {author: 'Джон Гарднер', quote: 'Одна из причин, по которым люди перестают учиться, - это страх ошибаться.'},
        {author: 'Бенджамин Франклин', quote: 'Незнание не стыдно, стыдно не стремиться к знаниям.'},
        {author: 'Марк Твен', quote: 'Предполагать хорошо, добраться до истины лучше.'},
        {
          author: 'Энтони Жд ДиАнжело',
          quote: 'Развивайте страсть к обучению. Если у вас получится, то вы всегда будете расти.'
        },
        {
          author: 'Алек Борн',
          quote: 'Заполнить свой разум миллионов разных фактов, но при этом ничему не научиться вполне возможно.'
        },
      ],
      selected_quotes: {}
    }
  },
  methods: {
    toHome() {
      document.location.replace('https://bmipk.ru/')
    },
    randomQuotes() {
      this.selected_quotes = this.quotes[Math.floor(Math.random() * this.quotes.length)]
    },
    Random() {
      setInterval(this.randomQuotes, 20000)
    }

  },
  created() {
    this.randomQuotes()
    this.Random()
  }
}
</script>

<style scoped lang="scss">
.header {
  padding: 5px 0 1px 0;
  text-shadow: 0.2px 1.2px 0.5px white;


  .header_right {
    margin: auto;
    text-align: left;
    display: flex;

    .header_right_item {
      white-space: nowrap;
    }
  }

  .header_midl, {
    margin: auto;
    align-self: center;


    .author {
      font-style: italic;
    }

  }

  .header_left {
    display: flex;
    cursor: pointer;
    margin: auto;
    text-align: center;

    .header_left_logo {
      max-width: 300px;
      margin: auto;
      img{
        max-height: 200px;
      }
    }

    .header_left_content {
      align-self: center;
      text-align: left;
      padding-left: 5px;
      margin: auto;


      h1 {
        font-size: 24px;
      }

      h3 {
        font-size: 16px;
      }


    }

    @media (max-width: 575px) {

      h1 {
        font-size: 10px;
      }
      h3 {
        text-align: center;
      }
    }
  }
}


</style>